<template>
  <Loader :value="promises.fetch" class="form">
    <template v-if="config">
      <template v-if="!done">
        <header
          v-if="config.metadata"
          :class="['form__header', headerImage && 'form__header--image']"
          :style="
            headerImage &&
            `background-image: linear-gradient(0, rgb(0 0 0 / 0.5), transparent 50%), url(${headerImage});`
          "
        >
          <h1 class="form__header-title">{{ config.metadata.title }}</h1>
        </header>

        <form class="form__content" @submit.prevent="submit">
          <h2 class="form__content-title">{{ config.label }}</h2>
          <CustomFields
            v-if="config.metadata.data && config.metadata.schema.length"
            :value="config.metadata.data"
            :schema="config.metadata.schema"
            :validator="validator"
          />
          <CustomFields v-model="data" :schema="config.schema" :validator="validator" :components="components" />
          <div class="form__content-actions">
            <ButtonComponent
              type="submit"
              label="Fullfør"
              :promise="promises.submit"
              :disabled="!validator.passes"
              :icon="['fal', 'arrow-to-right']"
              theme="primary"
            />
          </div>
        </form>
      </template>

      <div class="form-success-message" v-else>
        <FontAwesomeIcon class="form-success-message__icon" :icon="['fal', 'check-circle']" size="lg" />
        <h1 class="form-success-message__title">Skjemaet er sendt!</h1>
        <p class="form-success-message__body">
          Du vil snart bli tilsendt kvittering per epost. Du kan nå lukke dette viduet.
        </p>
        <ButtonComponent theme="primary" label="Lukk vinduet" :icon="['fal', 'arrow-to-right']" @click="closeWindow" />
      </div>
    </template>
  </Loader>
</template>

<script>
import Validator from '@/mixins/validator-v2'

import Card from '@kvass/card'
import CustomFields, { getValidatorConfig } from '@kvass/custom-fields'
import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomComponents from '@/components/Form/CustomFields'

export default {
  mixins: [
    Validator({
      rules: 'validatorConfig.rules',
      data: 'data',
      labels: 'validatorConfig.labels',
    }),
  ],
  props: {
    target: String,
    id: String,
    token: String,
    schema: String,
    residential: String,
    user: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      data: {},
      config: null,
      promises: {
        submit: null,
        fetch: null,
      },
      done: false,
      components: Object.values(CustomComponents),
    }
  },
  computed: {
    validatorConfig() {
      if (!this.config) return {}
      return getValidatorConfig(this.config.schema, this.data)
    },
    headerImage() {
      return this.config.metadata.cover ? this.config.metadata.cover.url : ''
    },
  },
  methods: {
    submit() {
      this.promises.submit = fetch(`${this.target}/api/integration/${this.id}/callbacks/default${location.search}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.data),
      })
        .then(res => res.json())
        .then(() => (this.done = true))
    },
    fetch() {
      const params = new URLSearchParams({ template: this.schema })
      if (this.residential) params.append('residential', this.residential)
      if (this.user) params.append('user', this.user)

      this.promise = fetch(`${this.target}/api/integration/${this.id}/callbacks/schema?${params.toString()}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(res => res.json())
        .then(data => {
          this.config = data
          this.data = this.config.data
        })
    },
    closeWindow() {
      window.close()
    },
  },
  components: {
    Card,
    CustomFields,
    ButtonComponent,
    Loader,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.form {
  &__content {
    margin: 2rem auto;
    max-width: $form-limit;
    padding-inline: $outerSpacing;

    &-actions {
      margin-top: 2rem;
    }
  }

  &__header {
    width: 100%;
    height: 20vh;
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &-title {
      max-width: $form-limit;
      width: 100%;
      margin: 0 auto;
      padding: $outerSpacing;
    }

    &--image {
      height: 40vh;
      background-color: #f5f5f5;

      .form__header-title {
        color: white;
        text-shadow: 0 2px 2px rgba(black, 0.5);
      }
    }
  }

  &-success-message {
    text-align: center;
    margin-block: 4rem;

    &__title,
    &__body {
      margin: 0.5rem;
    }

    .elder-button {
      margin-top: 2rem;
    }

    &__icon {
      width: 2rem !important;
      height: 2rem;
      margin-bottom: 1rem;
      color: var(--success);
    }
  }
}
</style>
