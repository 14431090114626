<template>
  <Card theme="flat" tag="form" class="image-marker-edit" @submit.prevent="submit">
    <template #default>
      <InputComponent :label="$t('edit')">
        <Leaflet
          v-model="coordinates"
          :options="{ preferCanvas: true }"
          :image="value.url"
          ref="leaflet"
          :controllers="['circle', 'polygon']"
          @add="add"
        />
      </InputComponent>
    </template>
    <template #footer>
      <slot name="footer" />
      <ButtonComponent :label="$t('cancel')" type="button" @click="$emit('close')" :icon="['fal', 'times']" />
      <ButtonComponent
        type="submit"
        :label="$t('confirm')"
        theme="primary"
        :promise="promise"
        :success-label="$t('completed')"
        @onSuccess="$emit('close')"
      />
    </template>
  </Card>
</template>

<script>
import toImage from '../Leaflet/plugins/toImage'

import Leaflet from '../Leaflet'
import Card from '@kvass/card'
import { InputComponent } from 'vue-elder-input'
import { ButtonComponent } from 'vue-elder-button'

export default {
  props: {
    value: Object,
    upload: Function,
  },
  watch: {
    value: {
      handler: 'reset',
      immediate: true,
    },
  },
  data() {
    return {
      coordinates: [],
      promise: null,
    }
  },
  methods: {
    add(layer) {
      this.coordinates.push(layer)
    },
    submit() {
      this.promise = toImage(this.$refs.leaflet.map)
        .then(file => this.upload(file, () => {}, { compression: 'default' }))
        .then(res => this.$emit('input', { ...res, name: this.value.name }))
    },
    reset() {
      this.coordinates = []
    },
  },

  components: {
    Leaflet,
    Card,
    InputComponent,
    ButtonComponent,
  },
}
</script>

<style lang="scss">
.image-marker-edit {
  max-width: 1000px;
  width: 100%;
}
</style>
