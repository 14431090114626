<template>
  <Loader :value="promise" theme="overlay" class="leaflet">
    <div class="leaflet__aspect" :style="style">
      <div ref="map" class="leaflet__map content"></div>
    </div>
  </Loader>
</template>

<script>
import { mergeDeepRight } from 'ramda'
import Leaflet from 'leaflet'
import 'leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import Localization from './localization'
import { path } from 'ramda'
import { getDimensions, setByPath } from '@/utils'
import { LoaderComponent as Loader } from 'vue-elder-loader'

const DefaultStyle = {
  weight: 2,
  color: '#e7201b',
  fillColor: '#e7201b',
  fillOpacity: 0,
  opacity: 1,
}

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    image: String,
    getStyle: {
      type: Function,
      default: () => ({}),
    },
    getTitle: {
      type: Function,
      default: () => undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    controllers: {
      type: Array,
      default: () => ['rectangle', 'polygon'],
    },
    geometryPath: String,
    idResolver: [String, Function],
  },
  watch: {
    image: {
      handler: 'init',
      immediate: true,
    },
    value: {
      handler: 'render',
    },
  },
  data() {
    return {
      promise: null,
      map: null,
      layer: null,
      mode: null,
      dimensions: null,
    }
  },
  computed: {
    ratio() {
      if (!this.dimensions) return 0
      return this.dimensions.height / this.dimensions.width
    },
    style() {
      if (!this.dimensions) return {}

      let maxHeight = window.innerHeight - 250
      let maxWidth = Math.min((this.dimensions.width / this.dimensions.height) * maxHeight, this.dimensions.width)

      return {
        '--map-ratio': `${this.ratio * 100}%`,
        maxWidth: `${maxWidth}px`,
      }
    },
  },
  methods: {
    getGeometry(item) {
      if (!this.geometryPath) return item
      return path(this.geometryPath.split('.'), item)
    },
    getId(item) {
      if (!this.idResolver) return item
      if (typeof this.idResolver === 'function') return this.idResolver(item)
      return path(this.idResolver.split('.'), item)
    },
    render() {
      this.layer.clearLayers()

      this.value.forEach(item => {
        let layer
        let style = mergeDeepRight(DefaultStyle, this.getStyle(item))

        switch (item.geometry.type) {
          case 'Point':
            layer = Leaflet.circle(this.getGeometry(item).geometry.coordinates, item.properties.radius, style)
            break
          default:
            layer = Leaflet.GeoJSON.geometryToLayer(this.getGeometry(item), style)
            break
        }

        layer._external_id = this.getId(item)
        layer.on('click', e => {
          if (this.mode) return
          this.$emit('click:layer', layer)
        })

        this.layer.addLayer(layer)

        let title = this.getTitle(item)
        if (title) layer.bindTooltip(title, { direction: 'center' })
      })
    },
    addLayer(layer) {
      this.$emit('add', this.prepareLayer(layer))
    },
    prepareLayer(layer) {
      let geo = layer.toGeoJSON()

      switch (geo.geometry.type) {
        case 'Point':
          return {
            type: 'Feature',
            properties: {
              radius: layer.getRadius(),
            },
            geometry: {
              type: 'Point',
              coordinates: [layer.getLatLng().lat, layer.getLatLng().lng],
            },
          }
        default:
          return geo
      }
    },
    editLayers(layers) {
      let items = {}

      layers.eachLayer(layer => (items[layer._external_id] = this.prepareLayer(layer)))

      this.$emit(
        'input',
        this.value.map(v => {
          let id = this.getId(v)
          if (id in items) {
            if (this.geometryPath) setByPath(this.geometryPath, items[id], v)
            else return items[id]
          }
          return v
        }),
      )
    },
    deleteLayers(layers) {
      let ids = []

      layers.eachLayer(layer => ids.push(layer._external_id))

      this.$emit(
        'input',
        this.value.filter(v => !ids.includes(this.getId(v))),
      )
    },
    init() {
      this.destroyMap()

      this.promise = getDimensions(this.image)
        .then(dimensions => {
          this.dimensions = dimensions
          return new Promise(resolve => {
            this.$nextTick(() => resolve(dimensions))
          })
        })
        .then(dimensions => {
          Leaflet.CRS.CustomZoom = Leaflet.extend({}, Leaflet.CRS.Simple)

          this.map = Leaflet.map(this.$refs.map, {
            minZoom: -3,
            maxZoom: 3,
            center: [0, 0],
            zoom: 0,
            zoomSnap: 0.01,
            scrollWheelZoom: false,
            crs: Leaflet.CRS.CustomZoom,
            ...this.options,
          })

          let southWest = this.map.unproject([0, dimensions.height], 0)
          let northEast = this.map.unproject([dimensions.width, 0], 0)
          let bounds = new Leaflet.LatLngBounds(southWest, northEast)

          Leaflet.imageOverlay(this.image, bounds).addTo(this.map)
          this.map.fitBounds(bounds, { animate: false })
          this.map.setMaxBounds(bounds, { animate: false })

          this.layer = new Leaflet.featureGroup()
          this.map.addLayer(this.layer)

          Leaflet.drawLocal = Localization

          let drawControl = new Leaflet.Control.Draw({
            position: 'bottomleft',
            edit: { featureGroup: this.layer },
            draw: {
              polyline: this.controllers.includes('polyline'),
              circle: this.controllers.includes('circle'),
              circlemarker: this.controllers.includes('circlemarker'),
              polygon: this.controllers.includes('polygon') ? { allowIntersection: false } : false,
              rectangle: this.controllers.includes('rectangle'),
              marker: this.controllers.includes('marker'),
            },
          })

          this.map.addControl(drawControl)
          this.map.on(Leaflet.Draw.Event.CREATED, e => this.addLayer(e.layer))
          this.map.on(Leaflet.Draw.Event.EDITED, e => this.editLayers(e.layers))
          this.map.on(Leaflet.Draw.Event.DELETED, e => this.deleteLayers(e.layers))

          this.map.on(Leaflet.Draw.Event.EDITSTART, () => (this.mode = 'edit'))
          this.map.on(Leaflet.Draw.Event.EDITSTOP, () => {
            if (this.mode !== 'edit') return
            this.mode = null
          })

          this.map.on(Leaflet.Draw.Event.DELETESTART, () => (this.mode = 'delete'))
          this.map.on(Leaflet.Draw.Event.DELETESTOP, () => {
            if (this.mode !== 'delete') return
            this.mode = null
          })

          this.render()
        })
    },
    destroyMap() {
      if (!this.map) return
      this.map.off()
      this.map.remove()
    },
  },
  beforeDestroy() {
    this.destroyMap()
  },
  components: {
    Loader,
  },
}
</script>

<style lang="scss">
.leaflet {
  &__aspect {
    width: 100%;
    @include aspect-ratio(1, 1);

    &:before {
      padding-top: var(--map-ratio);
    }
  }

  .leaflet-control-attribution {
    display: none;
  }
}
</style>
