<template>
  <div class="confirmation-list">
    <strong>{{ label }}</strong>
    <ul class="confirmation-list__items">
      <Item :value="item" v-for="(item, index) in value" :key="index" :show-category="showCategory(item, index - 1)" />
    </ul>
  </div>
</template>

<script>
import { SortByKey } from '@/utils'

import Item from './Item'

export default {
  name: 'ConfirmationList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: Array,
    label: String,
  },
  watch: {
    items: 'init',
  },
  methods: {
    showCategory(item, index) {
      if (!item.category) return false
      if (index < 0) return true
      return this.value[index].category !== item.category
    },
    init() {
      if (!this.items) return
      this.$emit(
        'input',
        this.items
          .map(item => ({
            ...item,
            state: null,
            comment: null,
            images: [],
          }))
          .sort(SortByKey('category')),
      )
    },
  },
  mounted() {
    this.init()
  },
  components: {
    Item,
  },
}
</script>

<style lang="scss">
.confirmation-list {
  &__items {
    margin: 0;
    padding: 0;
  }
}
</style>
