import Leaflet from 'leaflet'

export default async function (map) {
  try {
    let dimensions = map.getSize()

    let canvas = document.createElement('canvas')
    canvas.width = dimensions.x
    canvas.height = dimensions.y
    let ctx = canvas.getContext('2d')

    let layers = []
    map.eachLayer(l => layers.push(l))

    map.fitBounds(layers.find(l => l instanceof Leaflet.ImageOverlay).getBounds(), { animate: false })

    const Handlers = {
      async ImageOverlay(l) {
        try {
          let bounds = l.getBounds()
          let size = new Leaflet.Bounds(
            map.latLngToLayerPoint(bounds.getNorthWest()),
            map.latLngToLayerPoint(bounds.getSouthEast()),
          ).getSize()

          let image = await LoadImage(l._url)
          ctx.drawImage(image, 0, 0, size.x, size.y)
        } catch (err) {
          console.log(err)
          throw err
        }
      },
      PathRoot(l) {
        let bounds = map.getPixelBounds()
        let origin = map.getPixelOrigin()

        let pos = Leaflet.DomUtil.getPosition(l).subtract(bounds.min).add(origin)
        ctx.drawImage(l, pos.x, pos.y, canvas.width - pos.x * 2, canvas.height - pos.y * 2)
      },
    }

    for (let layer of layers) {
      if (layer.canvas) ctx.drawImage(layer.canvas, 0, 0)
      if (layer instanceof Leaflet.ImageOverlay) await Handlers.ImageOverlay(layer)
      if (map._pathRoot) Handlers.PathRoot(map._pathRoot)
      if (map._panes) {
        let target = map._panes.overlayPane.getElementsByTagName('canvas').item(0)
        if (target) Handlers.PathRoot(target)
      }
    }

    return await new Promise((resolve, reject) => {
      canvas.toBlob(res => (res ? resolve(res) : reject(new Error('Could not create blob'))))
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

function LoadImage(url) {
  return fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
    .then(res => res.blob())
    .then(blob => {
      return new Promise((resolve, reject) => {
        let el = new Image()
        el.onload = function () {
          resolve(this)
        }
        el.src = URL.createObjectURL(blob)
      })
    })
}
