<template>
  <li class="confirmation-list-item">
    <strong v-if="showCategory" class="confirmation-list-item__category">
      {{ value.category }}
    </strong>
    <div
      class="confirmation-list-item__column"
      :class="{ 'confirmation-list-item__column--active': value.state === false }"
    >
      <div class="confirmation-list-item__row">
        <p class="confirmation-list-item__label">{{ value.label }}</p>
        <Radio
          :items="[
            { label: 'Ja', value: true },
            { label: 'Nei', value: false },
          ]"
          v-model="value.state"
        />
      </div>
      <div v-if="value.state === false" class="confirmation-list-item__inputs">
        <InputComponent>
          <textarea class="elder-input__element" v-model="value.comment" rows="2" placeholder="Kommentar..." />
        </InputComponent>
        <FileComponent
          v-model="value.images"
          accept="image/*"
          :label="$tc('image', 2)"
          multiple
          :drop-message="$t('dragImageOrDrop')"
          :upload="upload"
        >
          <template #action="{ item }">
            <ButtonComponent
              type="button"
              theme="secondary"
              v-if="item.type.startsWith('image')"
              label="Legg til markering"
              @click="marker = item"
            />
          </template>
          <template #icon="{ item }">
            <img :src="item.url" :alt="item.name" />
          </template>
        </FileComponent>
      </div>
    </div>
    <ImageMarker :value="marker" :upload="upload" @input="setMarker" @close="marker = null" />
  </li>
</template>

<script>
import { VueComponentUpload } from '@kvass/storage'

import Radio from '@kvass/radio'
import { InputComponent } from 'vue-elder-input'
import { ButtonComponent } from 'vue-elder-button'
import { FileComponent } from 'vue-elder-file'
import ImageMarker from '@/components/ImageMarker/Component.vue'

export default {
  name: 'ConfirmationListItem',
  props: {
    value: Object,
    showCategory: Boolean,
  },
  data() {
    return {
      marker: null,
    }
  },
  methods: {
    upload(file, onProgress, options) {
      return VueComponentUpload(file, onProgress, {
        ...options,
        baseURL: this.$route.query.target,
        headers: {
          Authorization: `Kvass ${this.$route.query['kvass-token']}`,
        },
        credentials: 'omit',
      })
    },
    setMarker(file) {
      let index = this.value.images.findIndex(i => i === this.marker)
      if (index < 0) return

      return this.value.images.splice(index, 1, file)
    },
  },
  components: {
    Radio,
    InputComponent,
    ButtonComponent,
    FileComponent,
    ImageMarker,
  },
}
</script>

<style lang="scss">
.confirmation-list-item {
  list-style: none;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__column {
    padding: 1rem;
    border: 1px solid var(--vue-elder-border-color, #eaeaea);
    border-top-width: 0px;

    &--active {
      background-color: #fafafa;
    }
  }

  &__inputs {
    margin-top: 1rem;
  }

  &__category {
    margin-top: 1.5rem;
    margin-bottom: 0.2rem;
    display: block;

    & + .confirmation-list-item__column {
      border-top-width: 1px;
    }
  }

  &__label {
    margin: 0;
  }

  .elder-input {
    margin-bottom: 0.5rem;

    &__element {
      resize: none;
    }
  }

  .elder-file {
    &__thumbnail {
      align-items: flex-start;
    }

    img {
      height: 75px;
      background-color: var(--border-color);
    }
  }
}
</style>
