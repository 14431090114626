<template>
  <Modal v-if="value" v-on="$listeners" show clickaway class="image-marker">
    <Edit :value="value" @input="$ev => $emit('input', $ev)" v-bind="$attrs" v-on="$listeners" />
  </Modal>
</template>

<script>
import Edit from './Edit.vue'
import { ModalComponent as Modal } from 'vue-elder-modal'
import { ButtonComponent } from 'vue-elder-button'

export default {
  name: 'ImageMarker',
  props: {
    value: Object,
  },
  components: {
    Modal,
    Edit,
    ButtonComponent,
  },
}
</script>

<style lang="scss">
.image-marker {
}
</style>
