const drawToolbar = {
  actions: {
    title: 'Avbryt tegning',
    text: 'Avbryt',
  },
  finish: {
    title: 'Fullfør tegning',
    text: 'Fullfør',
  },
  undo: {
    title: 'Slett det sist tegnede punktet',
    text: 'Slett det siste punktet',
  },
  buttons: {
    polygon: 'Tegn en markering',
    polyline: 'Draw a polyline',
    rectangle: 'Draw a rectangle',
    circle: 'Draw a circle',
    marker: 'Draw a marker',
    circlemarker: 'Draw a circlemarker',
  },
}

const drawHandlers = {
  polygon: {
    error: 'Feil',
    tooltip: {
      start: 'Trykk for å tegne en markering.',
      cont: 'Trykk for å fortsette å tegne en markering.',
      end: 'Trykk på det første punktet for å lukke denne markeringen.',
    },
  },
  circle: {
    tooltip: {
      start: 'Click and drag to draw circle.',
    },
    radius: 'Radius',
  },
  circlemarker: {
    tooltip: {
      start: 'Click map to place circle marker.',
    },
  },
  marker: {
    tooltip: {
      start: 'Click map to place marker.',
    },
  },
  polyline: {
    error: '<strong>Error:</strong> shape edges cannot cross!',
    tooltip: {
      start: 'Click to start drawing line.',
      cont: 'Click to continue drawing line.',
      end: 'Click last point to finish line.',
    },
  },
  rectangle: {
    tooltip: {
      start: 'Click and drag to draw rectangle.',
    },
  },
  simpleshape: {
    tooltip: {
      end: 'Release mouse to finish drawing.',
    },
  },
}

const editToolbar = {
  actions: {
    save: {
      title: 'Lagre endringer.',
      text: 'Lagre',
    },
    cancel: {
      title: 'Avbryt redigering, forkast alle endringer.',
      text: 'Avbryt',
    },
    clearAll: {
      title: 'Slett alle markeringer.',
      text: 'Slett alle',
    },
  },
  buttons: {
    edit: 'Rediger markeringer.',
    editDisabled: 'Ingen markeringer å redigere.',
    remove: 'Slett markeringer.',
    removeDisabled: 'Ingen markeringer å slette.',
  },
}

const editHandlers = {
  edit: {
    tooltip: {
      text: 'Dra punktene, eller marker for å redigere.',
      subtext: 'Trykk på avbryt for å forkaste endringer.',
    },
  },
  remove: {
    tooltip: {
      text: 'Trykk på en markering for å slette.',
    },
  },
}

export default {
  draw: {
    toolbar: drawToolbar,
    handlers: drawHandlers,
  },
  edit: {
    toolbar: editToolbar,
    handlers: editHandlers,
  },
}
